video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;

}

.kirby {
    height:20%;
    background:none;
}

.hero-container {
    background: url('../../public/images/mario_jackson.gif') center center/cover no-repeat;
    
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contains;

}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    background-color:#222;
    border-radius:10px;
    padding: 1rem 2rem;
    margin: 10px;
    text-align:center;
}

.hero-container > p {
    color: #fff;
    background-color:#222;
    border-radius:10px;
    padding: 1rem 2rem;
    margin: 10px;
    text-align:center;
    font-size: 32px;
    font-family: 'Trebuchet MS','Lucida Sans Unicode','Lucida Grande','Lucida Sans', Arial, sans-serif;

}

.hero-btns{
    margin-top: 32px;
}

.hero-btns .btn{
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
    text-decoration: 'none';
}

.kirby-faceplant{

    height: 1em;
    padding-top: 3%;

}

.image_off, #home:hover .image_on{
    display:none;
    height: 1em;
    padding-top: 3%;
 }

.image_on, #home:hover .image_off{
    display:inline;
    height: 1em;
    padding-top: 3%;
 }

@media screen and (max-width: 960px){
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {

        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size:30px;
    }

    .btn-mobile{
        display: block;
        text-decoration:none;
    }
    .btn{
        width: 100%; 
    }
}