.bowser-sky {
    background: url('../../public/images/peaches_img/bowser.png') center center/cover no-repeat;

    height: 100vh;
    width: 100%;

    z-index: -1;

    position: absolute;


}

.bowser-island {
    background: url('../../public/images/peaches_img/bowser_island.png') center center/cover no-repeat;

    height: 100vh;
    width: 100%;

    z-index: 10;

    position: absolute;

}

.start-button {
    background: url('../../public/images/peaches_img/start.png')no-repeat center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;


    z-index: 11;
    float: none;
    animation: fade-in 2s forwards;




}



.parent-container {
    display: flex;
    width: 100%;
    height: 100vh;
}


.peach_pic {
    
    position: absolute;

    opacity: 1;
    float: none;
    width: 10vw;
    height: 10vh;
    object-fit: contain;
    z-index: -1;

}

@keyframes fade-out {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }
}


@keyframes fade-in {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}