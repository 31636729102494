.list-elements-info {
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    align-items: center;
    padding: 1rem 2rem;
    margin: 0.5%;
    margin-left: 8%;
    margin-right: 8%;
    word-break: break-word;


}

.inline_img{
    display:inline;
    height: 2em;
}

.list-elements-command {
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    align-items: center;
    padding: 1rem 2rem;
    margin: 0.5%;
    margin-left: 8%;
    margin-right: 8%;
    word-break: break-word;


}

.list-title {
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding: 1rem;

}

.list-subheading {
    font-size:3rem;
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    text-align: center;
    padding: 1rem 2rem;

    margin:5%;
    margin-top:1%;
    margin-bottom:1%;
    word-break: break-word;
    width:90%;

}

.list-subtitle {
    font-size: 1rem;
    display: flex;

    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding-bottom: 0.5rem;

}

.table-container{
    margin:20px;


}

.global_table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

}

.global_table td,
.global_table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.global_table td:nth-child(1){
    width: 15%;
}

.global_table td:nth-child(2){
    width: 7%;
    text-align:center;
}

.global_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.global_table tr:hover {
    background-color: #ddd;
}

.global_table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.channel_table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

}

.channel_table td,
.channel_table th {
    border: 1px solid #ddd;
    padding: 8px;
}


.channel_table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.channel_table tr:hover {
    background-color: #ddd;
}

.channel_table td:nth-child(1){
    width: 15%;
}

.channel_table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}
.nobreak{
    word-break:break-word;
}

@media screen and (max-width: 980px) {
    .list-elements {

        margin-left: 2%;
        margin-right: 2%;
    }
}