.list-elements {
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    align-items: center;
    padding: 1rem 2rem;
    margin: 0.5%;
    margin-left:8%;
    margin-right:8%;
    word-break: break-word;
    

}

.list-title {
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding: 1rem;

}

.list-subtitle {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding-bottom: 0.5rem;

}

@media screen and (max-width: 980px) {
    .list-elements {

        margin-left:2%;
        margin-right:2%;
    }
}