.nebula_background {
    background-color: black;
    z-index: -2;
    height: 100%;
    width: 100%;

}

.bowser-sky {
    backgrounddd: url('../../public/images/peaches_img/bowser.png') center center/cover no-repeat;
    background-color: black;
    height: 100vh;
    width: 100%;

    z-index: -1;

    position: absolute;


}

.peach-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    float: none;
}

.nebula {



    height: 100vh;
    width: 100%;

    z-index: -1;

    position: absolute;
    animation: zoom 50s infinite;
}

.peach_img_subcontainer {
    height: 100%;
    width: 100%;


    position: absolute;
    
    animation-iteration-count: 1;
   
}




@keyframes zoom {
    0% {
        opacity: 0;
        transform: scale(1.0);
        animation-timing-function: ease-in;
    }

    33% {
        opacity: 0.5;
        transform: scale(1.125);
        animation-timing-function: linear;
    }

    66% {
        opacity: 1;
        transform: scale(1.25);
        animation-timing-function: linear;
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
        animation-timing-function: ease-out;
    }
}

@keyframes zoom2 {
    0% {
        opacity: 0;
        transform: scale(0.9);
        animation-timing-function: linear;
    }

    0.2% {
        opacity: 1.0;
    }

    20% {

        transform: scale(1.0);
        animation-timing-function: linear;
    }

    40% {

        transform: scale(1.1);
        animation-timing-function: linear;
    }

    60% {
        transform: scale(1.2);
        animation-timing-function: linear;
    }

    80% {
        transform: scale(1.3);
        animation-timing-function: linear;
    }

    90% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
        animation-timing-function: ease-out;
    }
}

.bowser-island2 {
    background: url('../../public/images/peaches_img/bowser_island.png') center center/cover no-repeat;

    height: 100vh;
    width: 100%;

    z-index: 100;

    position: absolute;
    cursor: pointer;

}

.start-button {
    background: url('../../public/images/peaches_img/start.png')no-repeat center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;


    z-index: 101;
    float: none;
    animation: fade-in 2s forwards;




}



.parent-container {
    display: flex;
    width: 100vw;
    height: 100vh;

}


.peach_pic {

    position: absolute;

    opacity: 1;
    float: none;
    width: 10vw;
    height: 10vh;
    object-fit: contain;
    z-index: -1;

}

@keyframes fade-out {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0%;
    }
}


@keyframes fade-in {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}