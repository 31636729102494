.list-elements {
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    align-items: center;
    padding: 1rem 2rem;
    margin: 0.5%;
    margin-left:8%;
    margin-right:8%;
    word-break: break-word;
    

}

.list-title {
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding: 1rem;

}

.list-subtitle {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding-bottom: 0.5rem;

}

@media screen and (max-width: 980px) {
    .list-elements {

        margin-left:2%;
        margin-right:2%;
    }
}

.table-container{
    margin:20px;


}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

}
.nobreak{
    word-break:break-word;
}
#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #111111;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

@media screen and (max-width: 980px) {
    .list-elements {

        margin-left: 2%;
        margin-right: 2%;
    }
}