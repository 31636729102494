.list-elements {
    color: #fff;
    background-color: #222;
    border-radius: 10px;
    align-items: center;
    padding: 1rem 2rem;
    margin: 0.5%;
    margin-left:8%;
    margin-right:8%;
    word-break: break-word;
    

}

.list-title {
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding: 1rem;

}

.list-subtitle {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contains;
    padding: 1rem;

}

.QOTD{
    word-break: break-word;
    
}

@media screen and (max-width: 980px) {
    .list-elements {

        margin-left:2%;
        margin-right:2%;
    }
}

#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #333; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
  }
  
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }

  #bottomBtn:hover {
    background-color: #999; /* Add a dark-grey background on hover */
  }



.table-container{
    margin:20px;


}

.customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    

}
.nobreak{
    word-break:break-word;
}
.customers td,
.customers th {
    border: 1px solid #ddd;
    padding: 12px;
}

.customers tr:nth-child(even) {
    background-color: #f2f2f2;
    
}

.customers tr:hover {
    background-color: #ddd;
}

.customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    
}



@media screen and (max-width: 960px){
    .customers{
        font-size: 100%;
    }
    .customers td:nth-child(2),
    .customers th:nth-child(2),
    .customers td:nth-child(3),
    .customers th:nth-child(3)
    {
        display:none;
    }

}

@media screen and (max-width: 768px) {

    .customers{
        font-size: 80%;
    }
    
    
}